import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { kebabCase } from "lodash";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import withLayout from "../layout";
import SEO from "../components/seo";

import { SEO_KEYWORDS } from "../constants";

import "./posts.scss";

const BlogPage = (props) => {
    const posts = props.data.allMarkdownRemark.edges;
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "blog.title" });

    return (
        <main className="posts" role="main">
            <SEO
                keywords={SEO_KEYWORDS}
                lang={props.pageContext.locale}
                title={titleText}
            />
            <section className="posts-wrapper">
                <div className="container">
                    <div className="pure-g-r">
                        <div className="pure-u-1-3">
                            <header className="posts-wrapper-hd" role="banner">
                                <h1 className="h2 posts-title">
                                    <FormattedMessage id="blog.title" />
                                </h1>
                                <div className="posts-intro">
                                    <p className="f-copy">
                                        <FormattedMessage id="blog.intro" />
                                    </p>
                                </div>
                            </header>
                        </div>
                        <div className="pure-u-2-3">
                            <div className="posts-wrapper-bd">
                                <ul className="project-list plain-list">
                                    {posts.map((post) => (
                                        <li key={post.node.id} className="post">
                                            <div className="post-inner">
                                                <div className="post-bd clearfix">
                                                    <div className="media">
                                                        <div className="media-figure">
                                                            <Link
                                                                className="img-link"
                                                                to={
                                                                    post.node
                                                                        .fields
                                                                        .slug
                                                                }
                                                            >
                                                                <GatsbyImage
                                                                    image={
                                                                        post
                                                                            .node
                                                                            .frontmatter
                                                                            .thumbnail
                                                                            .childImageSharp
                                                                            .gatsbyImageData
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="post-metadata">
                                                                <h3 className="h3 post-title">
                                                                    <Link
                                                                        className="link"
                                                                        to={
                                                                            post
                                                                                .node
                                                                                .fields
                                                                                .slug
                                                                        }
                                                                    >
                                                                        {
                                                                            post
                                                                                .node
                                                                                .frontmatter
                                                                                .title
                                                                        }
                                                                    </Link>
                                                                </h3>
                                                                <h4 className="h4 post-summary">
                                                                    {
                                                                        post
                                                                            .node
                                                                            .frontmatter
                                                                            .excerpt
                                                                    }
                                                                </h4>
                                                                <h4 className="h4 post-published">
                                                                    {
                                                                        post
                                                                            .node
                                                                            .frontmatter
                                                                            .date
                                                                    }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {post.node.frontmatter
                                                        .tags ? (
                                                        <div className="tags-container">
                                                            <ul className="tag-pool">
                                                                {post.node.frontmatter.tags.map(
                                                                    (tag) => (
                                                                        <li
                                                                            key={
                                                                                tag +
                                                                                `tag`
                                                                            }
                                                                            className="tag-item"
                                                                        >
                                                                            <Link
                                                                                className="tag"
                                                                                to={`/tags/${kebabCase(
                                                                                    tag
                                                                                )}/`}
                                                                            >
                                                                                {
                                                                                    tag
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

const customProps = {
    localeKey: "blog",
};

export default withLayout(customProps)(injectIntl(BlogPage));

// Get all markdown data, in descending order by date, and grab the id, excerpt, slug, date, and title
export const pageQuery = graphql`
    {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { fileAbsolutePath: { regex: "/posts/" } }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        tags
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 176
                                    height: 99
                                    layout: FIXED
                                )
                            }
                        }
                        excerpt
                    }
                }
            }
        }
    }
`;
